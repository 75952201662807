import { authHeader } from '../../helpers';

const API_URL = process.env.REACT_APP_API_HOST + '/api'

export const resourceService = {
    addResource,
    updateResource,
    lastParticipantLeavingRoom,
    deleteResource
};

// -----------------
// private functions
// -----------------

// For other responses a 401 error means that the JWT is expired
// If this is the case, delete the use and perform a hard reload the page
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            if (response.status === 401) {
                // auto-logout if 401 response returned from api
                localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_FOR_USER);
                window.location.reload(true);
            }

            const error = data
            return Promise.reject(error);
        }

        return data;
    });
}

// -----------------
// service functions
// -----------------
function addResource(title, description, url, room){
    const endpoint = API_URL + '/resources';

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({title, description, url, room})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function deleteResource(resource_id){
    const endpoint = API_URL + '/resources/delete/' + resource_id;

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function updateResource(resource_id, title, description, url) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({ title, description, url })
    };

    return fetch(`${API_URL}/resources/${resource_id}`, requestOptions)
        .then(handleResponse)
}

function lastParticipantLeavingRoom(room_id){
    const endpoint = API_URL + '/resources/' + room_id;

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}
