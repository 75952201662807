import React from 'react';
import { connect} from 'react-redux';
import { Col, Avatar, Tooltip, Button, Badge } from 'antd';
import { roomActions } from '../store/actions';

class Participant_ extends React.Component {

    constructor(props) {
        super(props);
        this.showProfileDrawer = this.showProfileDrawer.bind(this);
        this.kickParticipant = this.kickParticipant.bind(this)
    }
    
    showProfileDrawer() {
        this.props.dispatch(roomActions.showProfileDrawer(this.props.participant))
    }

    kickParticipant(){
        if(this.props.count === "x") this.props.dispatch(roomActions.kickParticipantFromRoom(this.props.participant._id))
    }

    render() {
        var padding_value = 6
        if(this.props.size === "small") padding_value = 2
        return(
            <Col style={{padding: padding_value}}>
                <Badge count={this.props.count} offset={[0, 7]} showZero={false} style={{cursor: "pointer"}} onClick={() => this.kickParticipant()}>
                    <Tooltip placement="bottom" title={this.props.participant.firstName}>
                        <Button type="link" onClick={this.showProfileDrawer} size={this.props.size} shape="circle" style={{padding: 0, margin: 0, background: 'inherit'}}>
                                <Avatar size={this.props.size} src={this.props.participant.profilePicture}/>
                        </Button>
                    </Tooltip>
                </Badge>
            </Col>
        )
    }
}

function mapStateToProps(state) {
    return {
    };
}

const Participant = connect(mapStateToProps)(Participant_);
export { Participant }; 