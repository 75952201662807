import React from 'react';
import { connect } from 'react-redux';
import { resourceActions } from '../store/actions';
import { Col, Card, Input, Space, Button } from 'antd';
import {SaveOutlined} from '@ant-design/icons';

const { TextArea } = Input;

class UpdateResource extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description: "",
            url: "",
            url_updated: false
        }

        this.updateResource = this.updateResource.bind(this);
    }

    onChange = e => {
        switch(e.target.id){
            case "title":
                this.setState({
                    title: e.target.value
                });
                break
            case "description":
            this.setState({
                description: e.target.value
            });
            break
            case "url":
                this.setState({
                    url: e.target.value,
                    url_updated: true
                });
                break
            default:
                this.setState({
                });
                break
        }
    };

    updateResource(){
        const { resource_edit } = this.props;

        let title = this.state.title
        let description = this.state.description
        let url = this.state.url

        if(!title){title = resource_edit.title}
        if(!description){description = resource_edit.description}
        if(!this.state.url_updated){url = resource_edit.url}

        this.props.dispatch(resourceActions.updateResource(resource_edit._id, title, description, url))
    }

    checkForCompletness(){
        //const {resource_edit} = this.props
        let disabled = false
        /* if(!resource_edit.title && (!this.state.title || !this.state.description)){
            disabled = true
        }
        if(resource_edit.title && (!resource_edit.description && !this.state.description)){
            disabled = true
        }
        if(resource_edit.title && resource_edit.description && !this.state.url){
            disabled = true
        } */
        return disabled
    }

    render () {
        const {resource_edit} = this.props

        return (
            <Col style={{textAlign: 'center', padding: 5, width: '25vh', minWidth: 200}}>
                { resource_edit.title &&
                    <Card title={<Input style={{width: '100%', height: '16px'}} id="title" onChange={this.onChange} addonBefore="Title:" defaultValue={resource_edit.title} />} style={{height: '100%'}}>
                        <Space direction="vertical">
                            { !resource_edit.description &&
                                <TextArea autoSize style={{width: '100%'}} id="description" onChange={this.onChange} placeholder="eg. I love what you did here!"/>
                            }
                            { resource_edit.description &&
                                <TextArea autoSize style={{width: '100%'}} id="description" onChange={this.onChange}  defaultValue={resource_edit.description}/>
                            }
                            { resource_edit.url &&
                                <TextArea autoSize style={{width: '100%'}} id="url" onChange={this.onChange} defaultValue={resource_edit.url}/>
                            }
                            <Button type="secondary" shape="round" icon={<SaveOutlined/>} onClick={this.updateResource} disabled={this.checkForCompletness()}>
                                Save
                            </Button>
                        </Space>
                    </Card>
                }
            </Col>
        )
    }
}

function mapStateToProps(state) {
    const { selectedRoom } = state.rooms;
    const { loggedIn, user } = state.login;
    const { resource_edit} = state.resource;
    return {
        selectedRoom,
        loggedIn,
        user,
        resource_edit
    };
}

const connectedUpdateResource = connect(mapStateToProps)(UpdateResource );
export { connectedUpdateResource as UpdateResource  }; 