import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Divider, Col, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { roomActions } from '../store/actions';
import { Participant } from './Participant';

const { Meta } = Card;


class RoomCard extends React.Component {
    constructor(props) {
        super(props);

        this.showRoomDrawer = this.showRoomDrawer.bind(this);
    }

    renderParticipants(){
        const { room } = this.props;
        return room.participants
            .map(p => <Participant participant={p} count={0} size={"small"} key={p._id}/>)
    }

    showRoomDrawer() {
        if(!this.props.room.locked)
            this.props.dispatch(roomActions.showRoomDrawer(this.props.room))
    }

    render() {
        const { room, user } = this.props;

        let color = '#fffff';
        if(room.locked) color = '#DBDBDB'
        if(room.participants.filter(p => p._id === user._id).length > 0){
            color = '#e6f7ff';
        }

        return (
            <Col style={{padding: 5, width: '25vh', minWidth: 200}}>
                <Card title={room.title} bordered={true} extra={
                        !room.locked &&
                            <Button type="link" icon={<RightOutlined/>} onClick={this.showRoomDrawer}/>
                    }
                    style={{height: '100%', background: color}}>
                        <Meta
                            description={room.description}
                        />
                        <Divider />
                        <div style={{overflowX: 'auto', marginTop: -16}}>
                            <Row style={{maxHeight: 85}}>
                                {this.renderParticipants()}
                            </Row>
                        </div>
                </Card>
            </Col>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    return {
        user,
        loggedIn
    };
}

const connectedRoomCard = connect(mapStateToProps)(RoomCard);
export { connectedRoomCard as RoomCard }; 
