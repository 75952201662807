import React from 'react';
import { connect } from 'react-redux';

import { authActions } from '../../store/actions';

import { Form, Input, Button, Col } from 'antd';

const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 8,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 4,
      span: 16,
    },
  };

class UpdateUserView extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            firstName: '',
            lastName: '',
            submitted: false
        };

    }
 
    onSubmit = values => {
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { firstName, lastName, currentStatus, classOf, phoneNumber, website } = values;
        dispatch(authActions.updateUser(firstName, lastName, currentStatus, classOf, phoneNumber, website));
        alert("Your profile info was changed")
      };

    render() {
        const { user } = this.props;

        return (
            <div className="view-update-user">
                
                <Col style={{padding: 5, width: '25vh', minWidth: 200}}>
                    <h3>Update your profile info:</h3>
                </Col>

                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    hideRequiredMark={true}
                    onFinish={this.onSubmit}
                    >
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your first name!',
                        },
                        ]}
                        initialValue={user.firstName}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your last name!',
                        },
                        ]}
                        initialValue={user.lastName}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Current Status"
                        name="currentStatus"
                        rules={[
                        {
                            required: false,
                            message: 'Please input your current status!',
                        },
                        ]}
                        initialValue={user.currentStatus}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Class"
                        name="classOf"
                        rules={[
                        {
                            required: false,
                            message: 'Please input your class!',
                        },
                        ]}
                        initialValue={user.classOf}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                        {
                            required: false,
                            message: 'Please input your phone number!',
                        },
                        ]}
                        initialValue={user.phoneNumber}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Linked-In"
                        name="website"
                        rules={[
                        {
                            required: false,
                            message: 'Please input your linked-in!',
                        },
                        ]}
                        initialValue={user.website}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                        Update profile info
                        </Button>
                    </Form.Item>
                </Form>
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loading, errors, user } = state.login;
    return {
        loading,
        errors,
        user
    };
}

const connectedUpdateUserView = connect(mapStateToProps)(UpdateUserView);
export { connectedUpdateUserView as UpdateUserView };
