import React from 'react';
import { connect } from 'react-redux';

import { Layout, PageHeader, Divider, Button, Row, Col, Input, Space, Checkbox} from 'antd';
import { LogoutOutlined, PlusOutlined, SaveOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { VCHeader } from '../../components/VCHeader';
import { VCSider } from '../../components/VCSider';
import { roomActions } from '../../store/actions/room.actions';
import { resourceActions } from '../../store/actions/resource.actions';
import { Participant } from '../../components/Participant';
import { Resource } from '../../components/Resource';
import { NewResource } from '../../components/NewResource';
import { UpdateResource } from '../../components/UpdateResource';

import { ProfilePopup } from '../../components/ProfilePopup';

const {Content} = Layout;

class RoomDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            description: "",
            editable: true,
            conversation_url: "",
            editable_url: true,
            locked: false,
            permanent: false,
            cleaning: false,
            edit_resources: false
        }

        this.leaveRoom = this.leaveRoom.bind(this);
        this.changeSessionDescription = this.changeSessionDescription.bind(this);
        this.changeSessionUrl = this.changeSessionUrl.bind(this);

    }

    componentDidMount() {
        const room_id = this.props.match.params.room_id;
        this.requestRoom(room_id);
    }

    requestRoom(room_id) {
        this.props.dispatch(roomActions.getOne(room_id));
    }

    leaveRoom(){
        const { selectedRoom } = this.props;
        if(selectedRoom.participants.length === 1){
            this.props.dispatch(roomActions.lastParticipantLeavingRoom(selectedRoom._id))
            this.props.dispatch(resourceActions.lastParticipantLeavingRoom(selectedRoom._id))
        }
        
        this.props.dispatch(roomActions.addParticipantToRoom('null'))
    }

    renderParticipants(){
        const { selectedRoom, user, kickedParticipants } = this.props;

        return selectedRoom.participants
            .map(p => {
            var count;
            if(this.state.cleaning && p._id !== user._id) {count = "x"}
            else {count = 0}

            var deleted = false
                if(kickedParticipants && kickedParticipants.length > 0){
                    let i;
                    for (i = 0; i < kickedParticipants.length; i++) {
                        if (kickedParticipants[i] === p._id) {
                            deleted = true
                        }
                    }
                }

            if(deleted){
                return null
            }
            else{
                return <Participant participant={p} size={"large"} count={count} key={p._id}/>
            }
            })
        }

    renderResources(){
        const { selectedRoom, resources, updateResourceClicked, resource_edit, resources_edited, resources_deleted } = this.props;
        const all_resources = selectedRoom.resources.concat(resources)

        return all_resources
            .map((r) => {
                var deleted = false
                if(resources_deleted && resources_deleted.length > 0){
                    let i;
                    for (i = 0; i < resources_deleted.length; i++) {
                        if (resources_deleted[i]._id === r._id) {
                            deleted = true
                        }
                    }
                }

                if(deleted){
                    return null
                }
                else{
                    if(updateResourceClicked && r._id === resource_edit._id){
                        return <UpdateResource key={r._id}/>
                    }
                    else
                    {
                        if(resources_edited && resources_edited.length > 0){
                            var resource = <Resource resource={r} edit={this.state.edit_resources} key={r._id} />
                            var i;
                            for (i = 0; i < resources_edited.length; i++) {
                                if (resources_edited[i]._id === r._id) {
                                    resource = <Resource resource={resources_edited[i]} edit={this.state.edit_resources} key={resources_edited[i]._id} />
                                }
                            }
                            return resource;
                        }
                        else
                            return <Resource resource={r} edit={this.state.edit_resources} key={r._id} />   
                    }
                    }
                }
            )
    }

    onChange = e => {
        switch(e.target.id){
            case "session_description":
                this.setState({
                    description: e.target.value
                });
                break
            case "conversation_url":
                this.setState({
                    conversation_url: e.target.value
                });
                break
            default:
                this.setState({
                });
                break
        }
    };

    changeSessionDescription(){
        const { selectedRoom } = this.props;
        const description = this.state.description
        this.props.dispatch(roomActions.changeSessionDescription(selectedRoom._id, description))
        this.setState({editable: true})
    }

    checkForCompletness(){
        let disabled = false
        if(!this.state.conversation_url){
            disabled = true
        }
        else{
            if(this.state.conversation_url.length < 10 || this.state.conversation_url.length > 150){
                disabled = true
            }
            if(!this.state.conversation_url.includes("zoom.us")){
                disabled = true
            }
            if(!this.state.conversation_url.includes("https://")){
                disabled = true
            }
        }
        return disabled
    }

    changeSessionUrl(){
        const { selectedRoom } = this.props;
        this.props.dispatch(roomActions.changeConversationURL(selectedRoom._id, this.state.conversation_url, this.state.locked, this.state.permanent))
        this.setState({editable_url: true})
    }

    render() {
        const { selectedRoom, addResourceClicked } = this.props;

        const comment = {
            title: "Comment",
            description: "",
            url: ""
        }

        /* const scribbl_io = {
            title: "Scribbl.io",
            description: "A really fun drawing game",
            url: "https://skribbl.io"
        }

        const miro = {
            title: "Miro",
            description: "A white board to be super productive",
            url: "https://miro.com"
        } */
        
        return (
            <Layout>
                <VCHeader/>
                <Layout style={{marginTop: 64}}>
                    <VCSider/>
                    <Content style={{ padding: '0', margin:'0 16px', overflow: 'hidden', minHeight: 'calc(100vh - 64px)', }}>
                    {selectedRoom &&
                    <>
                    <PageHeader
                    className="site-page-header"
                    title={selectedRoom.title}
                    subTitle={selectedRoom.description}
                    />
                    <div style={{ marginLeft: 24, marginRight: 24, padding: 24, minHeight: 380, overflowX: 'auto',  backgroundColor: 'white'}}>
                        <Divider orientation="left" style={{ marginTop: 0}}>
                            {<div>
                                <Space>
                                    <p style={{margin: 0, width: 90, textAlign: 'left'}}>Participants</p>
                                    {!this.state.cleaning && <EditOutlined onClick={() => this.setState({cleaning: true})}/>}
                                    {this.state.cleaning && <CheckOutlined onClick={() => this.setState({cleaning: false})}/>}
                                </Space>
                            </div>}
                        </Divider>
                        <div style={{overflowX: 'auto'}}>
                            <Row style={{maxHeight: 145}}>
                                {this.renderParticipants()}
                            </Row>
                        </div>
                        <Divider orientation="left" style={{ marginTop: 24}}>
                            {<div>
                                <Space>
                                    <p style={{margin: 0, width: 90, textAlign: 'left'}}>Session</p>
                                    {this.state.editable_url && <EditOutlined onClick={() => 
                                        this.setState({editable_url: false, conversation_url: selectedRoom.conversation, permanent: selectedRoom.permanent, locked: selectedRoom.locked})}/>}
                                    {!this.state.editable_url && <CheckOutlined onClick={() => this.setState({editable_url: true})}/>}
                                </Space>
                            </div>}
                        </Divider>
                        {!this.state.editable_url &&
                        <Row style={{width: '100%', paddingBottom: 10}}>
                            <Col flex="auto">
                            <Input id="conversation_url" disabled={this.state.editable_url} addonBefore="URL:" onChange={this.onChange} defaultValue={selectedRoom.conversation}/>
                            </Col>
                            <Col flex="200px" style={{paddingLeft: 10}}>
                            <Space>
                                <Checkbox disabled={this.state.editable_url} defaultChecked={selectedRoom.permanent} onChange={e => this.setState({permanent: e.target.checked})}>permanent</Checkbox>
                                <Checkbox disabled={this.state.editable_url} defaultChecked={selectedRoom.locked} onChange={e => this.setState({locked: e.target.checked})}>locked</Checkbox>
                            </Space>
                            </Col>
                            <Col flex="100px" style={{paddingLeft: 10}}>
                            {this.state.editable_url &&
                            <Button type="secondary" shape="round" icon={<EditOutlined />} onClick={() => this.setState({conversation_url: selectedRoom.conversation, editable_url: false})}>
                                Edit
                            </Button>
                            }
                            {!this.state.editable_url &&
                            <Button type="secondary" shape="round" icon={<SaveOutlined/>} onClick={this.changeSessionUrl} disabled={this.checkForCompletness()}>
                                Set
                            </Button>
                            }
                            </Col>
                        </Row>
                        }
                        {selectedRoom.currentSessionDescription &&
                        <Row style={{width: '100%'}}>
                            <Col flex="auto">
                            <Input id="session_description" disabled={this.state.editable} addonBefore="Description:" onChange={this.onChange} defaultValue={selectedRoom.currentSessionDescription}/>
                            </Col>
                            <Col flex="100px" style={{paddingLeft: 10}}>
                            {this.state.editable &&
                            <Button type="secondary" shape="round" icon={<EditOutlined />} onClick={() => this.setState({description: selectedRoom.currentSessionDescription, editable: false})}>
                                Edit
                            </Button>
                            }
                            {!this.state.editable &&
                            <Button type="secondary" shape="round" icon={<SaveOutlined/>} onClick={this.changeSessionDescription}>
                                Set
                            </Button>
                            }
                            </Col>
                        </Row>
                        }
                        {!selectedRoom.currentSessionDescription &&
                        <Row style={{width: '100%'}}>
                        <Col flex="auto">
                            <Input id="session_description" onChange={this.onChange} addonBefore="Description:" placeholder="What you are currently working on?"/>
                        </Col>
                        <Col flex="100px" style={{paddingLeft: 10}}> 
                            <Button type="secondary" shape="round" icon={<SaveOutlined/>} onClick={this.changeSessionDescription}>
                                Set
                            </Button>
                        </Col>
                        </Row>
                        }
                        <Divider orientation="left" style={{ marginTop: 24}}>
                            {<div>
                                <Space>
                                    <p style={{margin: 0, width: 90, textAlign: 'left'}}>Resources</p>
                                    {!this.state.edit_resources && <EditOutlined onClick={() => this.setState({edit_resources: true})}/>}
                                    {this.state.edit_resources && <CheckOutlined onClick={() => this.setState({edit_resources: false})}/>}
                                </Space>
                            </div>}
                        </Divider>
                        <Row>
                            {this.renderResources()}
                            {addResourceClicked &&
                                <NewResource/>
                            }
                        </Row>
                        <Divider orientation="left" style={{ marginTop: 48}}>Actions</Divider>
                        <Space>
                            <Button type="primary" shape="round" icon={<LogoutOutlined />} onClick={this.leaveRoom}>
                                Leave
                            </Button>
                            <Button type="secondary" shape="round" icon={<PlusOutlined/>} onClick={() => this.props.dispatch(resourceActions.clickResourceButton({}))}>
                                Add Resource
                            </Button>
                            <Button type="dashed" shape="round" icon={<PlusOutlined/>} onClick={() => this.props.dispatch(resourceActions.clickResourceButton(comment))}>
                                Add Comment
                            </Button>
                            {/* <Button type="dashed" shape="round" icon={<PlusOutlined/>} onClick={() => this.props.dispatch(resourceActions.clickResourceButton(scribbl_io))}>
                                Add Scribbl.io
                            </Button>
                            <Button type="dashed" shape="round" icon={<PlusOutlined/>} onClick={() => this.props.dispatch(resourceActions.clickResourceButton(miro))}>
                                Add Mirro
                            </Button> */}
                        </Space>
                        </div>
                    </>}
                    </Content>
                </Layout>
                <ProfilePopup/>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    const { rooms, selectedRoom, kickedParticipants } = state.rooms;
    const { resources, addResourceClicked, updateResourceClicked,resource_edit, resources_edited, resources_deleted  } = state.resource;

    return {
        user,
        loggedIn,
        rooms,
        selectedRoom,
        resources,
        addResourceClicked,
        updateResourceClicked,
        resource_edit,
        resources_edited,
        resources_deleted,
        kickedParticipants
    };
}

const connectedRoomDetailPage = connect(mapStateToProps)(RoomDetailPage);
export { connectedRoomDetailPage as RoomDetailPage };
