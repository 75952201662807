import { roomConstants } from '../constants/room.constants';
import { roomService } from '../services/room.service';
import { authActions } from './auth.actions';

export const roomActions = {
    getAll,
    getOne,
    showRoomDrawer,
    hideRoomDrawer,
    showProfileDrawer,
    hideProfileDrawer,
    changeRoomFilter,
    addParticipantToRoom,
    kickParticipantFromRoom,
    changeConversationURL,
    lastParticipantLeavingRoom,
    changeSessionDescription
};

function getAll() {
    return dispatch => {
        dispatch(request());

        roomService.getAll()
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: roomConstants.GET_ROOMS_REQUEST_INITIATED } }
    function success(roomData) { return { type: roomConstants.GET_ROOMS_REQUEST_SUCCEEDED, roomData } }
    function failure(error) { return { type: roomConstants.GET_ROOMS_REQUEST_FAILED, error } }
}

function getOne(room_id) {
    return dispatch => {
        dispatch(request());

        roomService.getOne(room_id)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: roomConstants.GET_ROOM_REQUEST_INITIATED } }
    function success(roomData) { return { type: roomConstants.GET_ROOM_REQUEST_SUCCEEDED, roomData } }
    function failure(error) { return { type: roomConstants.GET_ROOM_REQUEST_FAILED, error } }
}

function showRoomDrawer(selectedRoom) {
    return dispatch => {
        dispatch({ type: roomConstants.SHOW_ROOM_DRAWER_REQUESTED, room: selectedRoom });
    };
}

function hideRoomDrawer(selectedRoom) {
    return dispatch => {
        dispatch({ type: roomConstants.HIDE_ROOM_DRAWER_REQUESTED, room: selectedRoom });
    };
}

function showProfileDrawer(selectedProfile) {
    return dispatch => {
        dispatch({ type: roomConstants.SHOW_PROFILE_DRAWER_REQUESTED, profile: selectedProfile });
    };
}

function hideProfileDrawer(selectedProfile) {
    return dispatch => {
        dispatch({ type: roomConstants.HIDE_PROFILE_DRAWER_REQUESTED, profile: selectedProfile });
    };
}

function changeRoomFilter(filter){
    return dispatch => {
        dispatch({ type: roomConstants.CHANGE_ROOM_FILTER_REQUESTED, filter: filter});
    };
}

function addParticipantToRoom(room_id){
    return dispatch => {
        dispatch(request());

        roomService.addParticipant(room_id)
            .then(
                response => {dispatch(success(response.data));
                if(room_id === 'null'){
                    window.location = '/map'}
                else{
                    window.location = '/rooms/' + room_id;
                }},
                error => dispatch(failure(error))
            );

        authActions.getUser();
    };

    function request() { return { type: roomConstants.ADD_PARTICIPANT_TO_ROOM_INITIATED } }
    function success(roomData) { return { type: roomConstants.ADD_PARTICIPANT_TO_ROOM_SUCCEEDED, roomData } }
    function failure(error) { return { type: roomConstants.ADD_PARTICIPANT_TO_ROOM_FAILED, error } }
}

function kickParticipantFromRoom(user_id){
    return dispatch => {
        dispatch(request());

        roomService.kickParticipant(user_id)
            .then(
                response => dispatch(success(user_id)),
                error => dispatch(failure(error))
            );

        authActions.getUser();
    };

    function request() { return { type: roomConstants.KICK_PARTICIPANT_FROM_ROOM_INITIATED } }
    function success(user_id) { return { type: roomConstants.KICK_PARTICIPANT_FROM_ROOM_SUCCEEDED, user_id } }
    function failure(error) { return { type: roomConstants.KICK_PARTICIPANT_FROM_ROOM_FAILED, error } }
}

function changeConversationURL(room_id, url, locked, permanent) {
    return dispatch => {
        dispatch(request());

        roomService.changeConversationUrl(room_id, url, locked, permanent)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: roomConstants.CHANGE_CONVERSATION_URL_INITIATED } }
    function success(roomData) { return { type: roomConstants.CHANGE_CONVERSATION_URL_SUCCEEDED, roomData } }
    function failure(error) { return { type: roomConstants.CHANGE_CONVERSATION_URL_FAILED, error } }
}

function changeSessionDescription(room_id, description) {
    return dispatch => {
        dispatch(request());

        roomService.changeSessionDescription(room_id, description)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: roomConstants.CHANGE_CONVERSATION_URL_INITIATED } }
    function success(roomData) { return { type: roomConstants.CHANGE_CONVERSATION_URL_SUCCEEDED, roomData } }
    function failure(error) { return { type: roomConstants.CHANGE_CONVERSATION_URL_FAILED, error } }
}

function lastParticipantLeavingRoom(room_id) {
    return dispatch => {
        dispatch(request());

        roomService.lastParticipantLeavingRoom(room_id)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: roomConstants.LAST_PARTICIPANT_LEAVING_ROOM_INITIATED } }
    function success(roomData) { return { type: roomConstants.LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED, roomData } }
    function failure(error) { return { type: roomConstants.LAST_PARTICIPANT_LEAVING_ROOM_FAILED, error } }
}
