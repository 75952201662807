import React from 'react';
import { connect } from 'react-redux';

import { authActions } from '../../store/actions';

import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}




class UpdatePictureView extends React.Component {

    state = {
        loading: false,
    };
    
      handleChange = info => {
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => {
            this.setState({
                imageUrl,
                loading: false,
              })
            const { dispatch } = this.props;
            dispatch(authActions.updateUserPicture(this.state.imageUrl));
          });
        }
      };
    
      render() {
        const uploadButton = (
          <div>
            {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
          </div>
        );
        const { imageUrl } = this.state;
        const dummyRequest = ({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        };
        
        return (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        );
      }


    

}

function mapStateToProps(state) {
    const { loading, errors, user } = state.login;
    return {
        loading,
        errors,
        user
    };
}

const connectedUpdatePictureView = connect(mapStateToProps)(UpdatePictureView);
export { connectedUpdatePictureView as UpdatePictureView };