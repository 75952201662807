import { resourceConstants } from '../constants/resource.constants';
import { resourceService } from '../services/resource.service';
import { authActions } from './auth.actions';

export const resourceActions = {
    clickResourceButton,
    clickUpdateResourceButton,
    addResource,
    updateResource,
    lastParticipantLeavingRoom,
    clickDeleteResourceButton
};

function clickResourceButton(resource_temp){
    return dispatch => {
        dispatch(success(resource_temp))
    }
    function success(resource_temp){ return {type: resourceConstants.ADD_RESOURCE_BUTTON_CLICKED, resource_temp}}
}

function addResource(title, description, url, room) {
    return dispatch => {
        dispatch(request());

        resourceService.addResource(title, description, url, room)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );

        authActions.getUser();
    };

    function request() { return { type: resourceConstants.ADD_RESOURCE_REQUEST_INITIATED } }
    function success(resourceData) { return { type: resourceConstants.ADD_RESOURCE_REQUEST_SUCCEEDED, resourceData } }
    function failure(error) { return { type: resourceConstants.ADD_RESOURCE_REQUEST_FAILED, error } }
}

function clickUpdateResourceButton(resource_temp){
    return dispatch => {
        dispatch(success(resource_temp))
    }

    function success(resource_temp){ return {type: resourceConstants.UPDATE_RESOURCE_BUTTON_CLICKED, resource_temp}}
}

function clickDeleteResourceButton(resource){
    return dispatch => {
        dispatch(request());

        resourceService.deleteResource(resource._id)
            .then(
                response => {
                    if(response.status === 1)
                    {console.log(response)
                    console.log(resource)
                    dispatch(success(resource))}},
                error => dispatch(failure(error))
            );

        authActions.getUser();
    };

    function request() { return { type: resourceConstants.DELETE_RESOURCE_REQUEST_INITIATED } }
    function success(resourceData) { return { type: resourceConstants.DELETE_RESOURCE_REQUEST_SUCCEEDED, resourceData } }
    function failure(error) { return { type: resourceConstants.DELETE_RESOURCE_REQUEST_FAILED, error } }
}

function updateResource(resource_id, title, description, url) {
    return dispatch => {
        dispatch(request(resource_id));

        resourceService.updateResource(resource_id, title, description, url)
            .then(
                response => {
                    const resource = response.data;
                    dispatch(success(resource));
                },
                error => {
                    dispatch(failure(error, resource_id));
                }
            );
    };

    function request(resource_id) { return { type: resourceConstants.UPDATE_RESOURCE_REQUEST_INITIATED, resource_id } }
    function success(resource) { return { type: resourceConstants.UPDATE_RESOURCE_REQUEST_SUCCEEDED, resource } }
    function failure(error, resource_id) { return { type: resourceConstants.UPDATE_RESOURCE_REQUEST_FAILED, error, resource_id } }
}

function lastParticipantLeavingRoom(room_id) {
    return dispatch => {
        dispatch(request());

        resourceService.lastParticipantLeavingRoom(room_id)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: resourceConstants.LAST_PARTICIPANT_LEAVING_ROOM_INITIATED } }
    function success() { return { type: resourceConstants.LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED } }
    function failure(error) { return { type: resourceConstants.LAST_PARTICIPANT_LEAVING_ROOM_FAILED, error } }
}