import React from 'react';
import { connect } from 'react-redux';

import { Menu, Layout} from 'antd';
import { SettingOutlined, UserOutlined} from '@ant-design/icons';
//import { AppstoreOutlined, RocketOutlined} from '@ant-design/icons';
import './VCHeader.scss';
import 'antd/dist/antd.css';

const { Header } = Layout;
const { SubMenu } = Menu;

class VCHeader extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            current: window.location.pathname
        }
    }

    componentDidMount() {
    this.setState({
        current: window.location.pathname
    })
    }
    
      handleClick = e => {
        this.setState({
          current: e.key,
        });
        window.location = e.key;
      };

    render() {
        const { loggedIn, user } = this.props;

        return (
            <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%'}}>
                <a href="/map">
                    <img className="logo" src="/logo.png" alt="Centerling News Logo"/>
                </a>
                <Menu onClick={this.handleClick} theme="light" selectedKeys={['/']} mode="horizontal">
                    {/* <Menu.Item key="/" icon={<AppstoreOutlined/>}>
                    Virtual Center
                    </Menu.Item> */}
                    {loggedIn &&
                        <SubMenu className="usertab" icon={<UserOutlined/>} title={user.firstName.concat(' ', user.lastName)}>
                            <Menu.Item key="/profile" icon={<SettingOutlined/>}>Account</Menu.Item>
                            {/* <Menu.Item key="2" icon={<RocketOutlined/>}>Status</Menu.Item> */}
                            <Menu.Item key="/logout" icon={<UserOutlined/>}>Logout</Menu.Item>
                        </SubMenu>
                    }
                    {!loggedIn &&
                        <Menu.Item className="usertab" key="/login" icon={<UserOutlined/>}>
                        login
                        </Menu.Item>
                    }
                </Menu>
            </Header>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    return {
        loggedIn,
        user
    };
}

const connectedVCHeader = connect(mapStateToProps)(VCHeader);
export { connectedVCHeader as VCHeader }; 
