import React from 'react';
import { connect } from 'react-redux';
import { roomActions } from '../store/actions';

import { Layout, Menu} from 'antd';
import {AppstoreOutlined, BuildOutlined, SelectOutlined} from '@ant-design/icons';
import './VCSider.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

class VCSider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };


  handleClick = e => {
    if(e.key !== '/' && e.keyPath.includes('/')){
      if(window.location.pathname !== '/') window.location = '/';
      this.props.dispatch(roomActions.changeRoomFilter(e.key));
    }
    else{
      if(window.location.pathname !== e.key) window.location = e.key
    }
  };

  render() {
      const { filter, user } = this.props;
      let selectedKey = window.location.pathname;
      if(selectedKey === '/'){
        selectedKey = filter
      }

      return (
          <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} theme="light">
          <Menu
            mode="inline"
            onClick={this.handleClick}
            selectedKeys={[selectedKey]}
            defaultOpenKeys={[window.location.pathname]}
            style={{ borderRight: 0, paddingTop: 15}}
          >
            <Menu.Item key="/map" icon={<BuildOutlined/>}>
                Map
            </Menu.Item>
            <SubMenu key="/" icon={<AppstoreOutlined />} title="Rooms" onTitleClick={this.handleClick}>
              <Menu.Item key="all">All Rooms</Menu.Item>
              <Menu.Item key="main">Main Rooms</Menu.Item>
              <Menu.Item key="lecture">Lecture Rooms</Menu.Item>
              <Menu.Item key="group">Group Rooms</Menu.Item>
              <Menu.Item key="ca">CA Rooms</Menu.Item>
              <Menu.Item key="active">Active Rooms</Menu.Item>
            </SubMenu>
            {user.room !== 'null' &&
              <Menu.Item key={"/rooms/"+ user.room} icon={<SelectOutlined/>}>
                Current Room
              </Menu.Item>
            }
          </Menu>
        </Sider>
      );
  }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    const {filter} = state.rooms;
    return {
        loggedIn,
        user,
        filter
    };
}

const connectedVCSider = connect(mapStateToProps)(VCSider);
export { connectedVCSider as VCSider}; 
