import { roomConstants } from '../constants/room.constants';

const initialState = { initialLoadHappened: false, loading: false, errors: {}, rooms: [], showRoomDrawer: false, selectedRoom: null, showProfileDrawer: false, selectedProfile: null, filter: 'all', kickedParticipants: [] };

export function rooms(state = initialState, action) {
  switch (action.type) {
    case roomConstants.GET_ROOMS_REQUEST_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.GET_ROOMS_REQUEST_SUCCEEDED:
      const data = action.roomData;
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: {},
        rooms: data
      }};
    case roomConstants.GET_ROOMS_REQUEST_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case roomConstants.GET_ROOM_REQUEST_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.GET_ROOM_REQUEST_SUCCEEDED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: {},
        selectedRoom: action.roomData
      }};
    case roomConstants.GET_ROOM_REQUEST_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case roomConstants.SHOW_ROOM_DRAWER_REQUESTED:
      return {...state, ...{
        selectedRoom: action.room,
        showRoomDrawer: true
      }};
    case roomConstants.HIDE_ROOM_DRAWER_REQUESTED:
      return {...state, ...{
        selectedRoom: null,
        showRoomDrawer: false
      }};
    case roomConstants.SHOW_PROFILE_DRAWER_REQUESTED:
      return {...state, ...{
        selectedProfile: action.profile,
        showProfileDrawer: true
      }};
    case roomConstants.HIDE_PROFILE_DRAWER_REQUESTED:
      return {...state, ...{
        selectedProfile: null,
        showProfileDrawer: false
      }};
    case roomConstants.CHANGE_ROOM_FILTER_REQUESTED:
      return {...state, ...{
        filter: action.filter
      }};
    case roomConstants.ADD_PARTICIPANT_TO_ROOM_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.ADD_PARTICIPANT_TO_ROOM_SUCCEEDED:
      return {...state, ...{
        selectedRoom: action.roomData,
        showRoomDrawer: false
      }};
    case roomConstants.ADD_PARTICIPANT_TO_ROOM_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case roomConstants.KICK_PARTICIPANT_FROM_ROOM_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.KICK_PARTICIPANT_FROM_ROOM_SUCCEEDED:
      return {...state, ...{
        kickedParticipants: [...state.kickedParticipants, action.user_id]
      }};
    case roomConstants.KICK_PARTICIPANT_FROM_ROOM_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case roomConstants.CHANGE_CONVERSATION_URL_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.CHANGE_CONVERSATION_URL_SUCCEEDED:
      return {...state, ...{
        selectedRoom: action.roomData,
        showRoomDrawer: false
      }};
    case roomConstants.CHANGE_CONVERSATION_URL_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case roomConstants.CHANGE_SESSION_DESCRIPTION_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.CHANGE_SESSION_DESCRIPTION_SUCEEDED:
      return {...state, ...{
        selectedRoom: action.roomData,
        showRoomDrawer: false
      }};
    case roomConstants.CHANGE_SESSION_DESCRIPTION_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case roomConstants.LAST_PARTICIPANT_LEAVING_ROOM_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case roomConstants.LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED:
      return {...state, ...{
        selectedRoom: action.roomData,
        showRoomDrawer: false
      }};
    case roomConstants.LAST_PARTICIPANT_LEAVING_ROOM_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    default:
      return state
  }
}
