import { authHeader } from '../../helpers';

const API_URL = process.env.REACT_APP_API_HOST + '/api'

export const roomService = {
    getAll,
    getOne,
    addParticipant,
    kickParticipant,
    changeConversationUrl,
    lastParticipantLeavingRoom,
    changeSessionDescription
};

// -----------------
// private functions
// -----------------

// For other responses a 401 error means that the JWT is expired
// If this is the case, delete the use and perform a hard reload the page
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            if (response.status === 401) {
                // auto-logout if 401 response returned from api
                localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_FOR_USER);
                window.location.reload(true);
            }

            const error = data
            return Promise.reject(error);
        }

        return data;
    });
}

// -----------------
// service functions
// -----------------
function getAll() {
    const endpoint = API_URL + '/rooms/';

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function getOne(room_id) {
    const endpoint = API_URL + '/rooms/' + room_id;

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function addParticipant(room_id){
    const endpoint = API_URL + '/auth/update-room';

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({room_id})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function kickParticipant(user_id){
    const endpoint = API_URL + '/auth/kick-from-room';

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({user_id: user_id})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function changeConversationUrl(room_id, conversation, locked, permanent){
    const endpoint = API_URL + '/rooms/conversation/' + room_id;

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({conversation: conversation, locked: locked, permanent: permanent})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function changeSessionDescription(room_id, currentSessionDescription){
    const endpoint = API_URL + '/rooms/session-description/' + room_id;

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify({currentSessionDescription})
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}

function lastParticipantLeavingRoom(room_id){
    const endpoint = API_URL + '/rooms/' + room_id;

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader()
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}