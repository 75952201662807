export const resourceConstants = {
    ADD_RESOURCE_BUTTON_CLICKED: 'ADD_RESOURCE_BUTTON_CLICKED',
    ADD_RESOURCE_REQUEST_INITIATED: 'ADD_RESOURCE_REQUEST_INITIATED',
    ADD_RESOURCE_REQUEST_SUCCEEDED: 'ADD_RESOURCE_REQUEST_SUCCEEDED',
    ADD_RESOURCE_REQUEST_FAILED: 'ADD_RESOURCE_REQUEST_FAILED',

    DELETE_RESOURCE_REQUEST_INITIATED: 'DELETE_RESOURCE_REQUEST_INITIATED',
    DELETE_RESOURCE_REQUEST_SUCCEEDED: 'DELETE_RESOURCE_REQUEST_SUCCEEDED',
    DELETE_RESOURCE_REQUEST_FAILED: 'DELETE_RESOURCE_REQUEST_FAILED',

    UPDATE_RESOURCE_BUTTON_CLICKED: 'UPDATE_RESOURCE_BUTTON_CLICKED',
    UPDATE_RESOURCE_REQUEST_INITIATED: 'UPDATE_RESOURCE_REQUEST_INITIATED',
    UPDATE_RESOURCE_REQUEST_SUCCEEDED: 'UPDATE_RESOURCE_REQUEST_SUCCEEDED',
    UPDATE_RESOURCE_REQUEST_FAILED: 'UPDATE_RESOURCE_REQUEST_FAILED',
    
    LAST_PARTICIPANT_LEAVING_ROOM_INITIATED: 'LAST_PARTICIPANT_LEAVING_ROOM_INITIATED',
    LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED: 'LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED',
    LAST_PARTICIPANT_LEAVING_ROOM_FAILED: 'LAST_PARTICIPANT_LEAVING_ROOM_FAILED'
}
