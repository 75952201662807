import React from 'react';
import { connect } from 'react-redux';
import { roomActions } from '../store/actions';
import { Drawer, Row, Divider, Button, Input } from 'antd';
import { Participant } from './Participant';
import { LoginOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography;

class RoomPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            childrenDrawer: false,
            input: ""
        };

        this.hideRoomDrawer = this.hideRoomDrawer.bind(this);
        this.enterRoom = this.enterRoom.bind(this);
        this.goToRoom = this.goToRoom.bind(this);
        this.setupZoom = this.setupZoom.bind(this);
    }

    renderParticipants(){
        const { selectedRoom } = this.props;
        return selectedRoom.participants
            .map(p => <Participant participant={p} key={p._id} />)
    }

    hideRoomDrawer() {
        const { selectedRoom } = this.props;

        this.props.dispatch(roomActions.hideRoomDrawer(selectedRoom))
    }

    enterRoom(){
        const { selectedRoom } = this.props;
        if(selectedRoom.conversation === ""){
            this.showChildrenDrawer()
        }
        else{
            this.props.dispatch(roomActions.addParticipantToRoom(selectedRoom._id));
            window.open(selectedRoom.conversation, "_blank");
        }
    }

    goToRoom(){
        const{user} = this.props;
        window.location = "/rooms/"+ user.room
    }


    setupZoom(){
        const{selectedRoom} = this.props;
        const url = this.state.input
        const locked = false;
        const permanent = false;
        this.props.dispatch(roomActions.changeConversationURL(selectedRoom._id, url, locked, permanent))
        this.props.dispatch(roomActions.addParticipantToRoom(selectedRoom._id));
    }

    showChildrenDrawer = () => {
        this.setState({
          childrenDrawer: true,
        });
      };
    
    onChildrenDrawerClose = () => {
    this.setState({
        childrenDrawer: false,
    });
    };

    onChange = e => {
        switch(e.target.id){
            case "zoom_link":
                this.setState({
                    input: e.target.value
                });
                break
            default:
                this.setState({
                });
                break
        }
    };

    checkForCompletness(){
        let disabled = false
        if(!this.state.input){
            disabled = true
        }
        else{
            if(this.state.input.length < 10 || this.state.input.length > 150){
                disabled = true
            }
            if(!this.state.input.includes("zoom.us")){
                disabled = true
            }
            if(!this.state.input.includes("https://")){
                disabled = true
            }
        }
        return disabled
    }

    render () {
        
        const { selectedRoom, user } = this.props

        if(selectedRoom) {

            return (
            
                <Drawer
                width={320}
                visible={this.props.showRoomDrawer}
                onClose={this.hideRoomDrawer}
                title={this.props.selectedRoom.title}
                >
                    <Divider orientation="left" style={{ marginTop: 0, marginBottom: 12}}>Description</Divider>
                    <Text>{selectedRoom.description}</Text>
                    <Divider orientation="left" style={{ marginTop: 24, marginBottom: 12}}>Participants</Divider>
                    <Row>
                            {this.renderParticipants()}
                    </Row>
                    {selectedRoom.currentSessionDescription &&
                        <div>
                            <Divider orientation="left" style={{ marginTop: 24, marginBottom: 12}}>Currently ongoing</Divider>
                            <Text>{selectedRoom.currentSessionDescription}</Text>
                        </div>
                    }
                    <Divider />
                    {user.room !== selectedRoom._id && selectedRoom.conversation !== "" &&
                        <Button type="primary" shape="round" icon={<LoginOutlined />} onClick={this.enterRoom}>
                            Join
                        </Button>
                    }
                    {user.room !== selectedRoom._id && selectedRoom.conversation === "" &&
                        <Button type="primary" shape="round" icon={<LoginOutlined />} onClick={this.enterRoom}>
                            Open room
                        </Button>
                    }
                    {user.room === selectedRoom._id &&
                        <Button type="primary" shape="round" icon={<LoginOutlined />} onClick={this.goToRoom}>
                            Go to room
                        </Button>
                    }
                    <Drawer
                        title="You are the first to join"
                        width={320}
                        closable={false}
                        onClose={this.onChildrenDrawerClose}
                        visible={this.state.childrenDrawer}
                    >
                        <p>Because you are the first one to join, please set up a new zoom call quickly!</p>
                        <Input id="zoom_link" addonBefore="URL:" placeholder="https://us02web.zoom.us/j/..." onChange={this.onChange}/>
                        <Divider />
                        <Button type="primary" shape="round" icon={<LoginOutlined />} disabled={this.checkForCompletness()} onClick={this.setupZoom}>
                            Enter room
                        </Button>
                    </Drawer>
                </Drawer>
            )    
        }
        else{ return null }
        
    }
    
}


function mapStateToProps(state) {
    const { showRoomDrawer, selectedRoom } = state.rooms;
    const { loggedIn, user } = state.login;
    return {
        showRoomDrawer,
        selectedRoom,
        loggedIn,
        user
    };
}

const connectedRoomPopup = connect(mapStateToProps)(RoomPopup );
export { connectedRoomPopup as RoomPopup  }; 