import React from 'react';
import { connect } from 'react-redux';
import { resourceActions } from '../store/actions';
import { Col, Card, Input, Space, Button } from 'antd';
import {SaveOutlined} from '@ant-design/icons';

class NewResource extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description: "",
            url: ""
        }

        this.saveResource = this.saveResource.bind(this);
    }

    onChange = e => {
        switch(e.target.id){
            case "title":
                this.setState({
                    title: e.target.value
                });
                break
            case "description":
            this.setState({
                description: e.target.value
            });
            break
            case "url":
                this.setState({
                    url: e.target.value
                });
                break
            default:
                this.setState({
                });
                break
        }
    };

    saveResource(){
        const { selectedRoom, resource_temp } = this.props;

        let title = this.state.title
        let description = this.state.description
        let url = this.state.url

        if(!title){title = resource_temp.title}
        if(!description){description = resource_temp.description}
        if(!url){url = resource_temp.url}

        this.props.dispatch(resourceActions.addResource(title, description, url, selectedRoom._id))
    }

    checkForCompletness(){
        const {resource_temp} = this.props
        let disabled = false
        if(!resource_temp.title && (!this.state.title || !this.state.description)){
            disabled = true
        }
        if(resource_temp.title && (!resource_temp.description && !this.state.description)){
            disabled = true
        }
        if(resource_temp.title && resource_temp.description && !this.state.url){
            disabled = true
        }
        return disabled
    }

    render () {
        const {resource_temp} = this.props

        return (
            <Col style={{textAlign: 'center', padding: 5, width: '25vh', minWidth: 200}}>
                { !resource_temp.title &&
                    <Card title={<Input style={{width: '100%', height: '16px'}} id="title" onChange={this.onChange} addonBefore="Title:" placeholder="eg. Whiteboard"/>} style={{height: '100%'}}>
                        <Space direction="vertical">
                            <Input style={{width: '100%'}} id="description" onChange={this.onChange} addonBefore="Description:" placeholder="eg. This shows our brainstroming session baou the Virtual Center"/>
                            <Input style={{width: '100%'}} id="url" onChange={this.onChange} addonBefore="URL:" placeholder="eg. https://miro.com"/>
                            <Button type="secondary" shape="round" icon={<SaveOutlined/>} onClick={this.saveResource} disabled={this.checkForCompletness()}>
                                Save
                            </Button>
                        </Space>
                    </Card>
                }
                { resource_temp.title &&
                    <Card title={<Input style={{width: '100%', height: '16px'}} id="title" onChange={this.onChange} addonBefore="Title:" value={resource_temp.title} disabled={true}/>} style={{height: '100%'}}>
                        <Space direction="vertical">
                            { !resource_temp.description &&
                                <Input style={{width: '100%'}} id="description" onChange={this.onChange} placeholder="eg. I love what you did here!"/>
                            }
                            { resource_temp.description &&
                                <Input style={{width: '100%'}} id="description" onChange={this.onChange} addonBefore="Description:" value={resource_temp.description}/>
                            }
                            { resource_temp.url &&
                                <Input style={{width: '100%'}} id="url" onChange={this.onChange} addonBefore="URL:" placeholder={resource_temp.url}/>
                            }
                            <Button type="secondary" shape="round" icon={<SaveOutlined/>} onClick={this.saveResource} disabled={this.checkForCompletness()}>
                                Save
                            </Button>
                        </Space>
                    </Card>
                }
            </Col>
        )
    }
}

function mapStateToProps(state) {
    const { selectedRoom } = state.rooms;
    const { loggedIn, user } = state.login;
    const { resource_temp} = state.resource;
    return {
        selectedRoom,
        loggedIn,
        user,
        resource_temp
    };
}

const connectedNewResource = connect(mapStateToProps)(NewResource );
export { connectedNewResource as NewResource  }; 