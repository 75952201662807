import React from 'react';
import { connect} from 'react-redux';
import { Card, Col, Row, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { roomActions } from '../store/actions';
import { Participant } from './Participant';

import './RoomMapCard.scss';

class RoomMapCard extends React.Component {

    constructor(props) {
        super(props);

        this.showRoomDrawer = this.showRoomDrawer.bind(this);
    }

    renderParticipants(){
        const { room } = this.props;
        return room.participants
            .map(p => <Participant style={{zIndex: 5}} count={0} participant={p} size={"small"} key={p._id}/>)
    }

    showRoomDrawer() {
        if(!this.props.room.locked)
            this.props.dispatch(roomActions.showRoomDrawer(this.props.room))
    }

    render() {
        const { room, user } = this.props;
        //let widthfactor = window.innerWidth/36;
        let widthfactor = 29;
        let widthfactor_2 = 80;
        let min_widthfactor = 25;
        let max_widthfactor = 56;


        if(room.room_type === 'empty'){
            return (
            <Col>
                <Card className="empty-card"  bordered={false} style={{height: 160, minWidth: min_widthfactor * room.map_info[2],width: (room.map_info[2]*widthfactor_2)/widthfactor +"vw",maxWidth: max_widthfactor * room.map_info[2], background: 'inherit'}}>
                </Card>
            </Col>
            )
        }
        if(room.room_type === 'hallway'){
            return (
            <Col>
                <Card className="hallway-card"  bordered={false} style={{height: 160, minWidth: min_widthfactor * room.map_info[2], width: (room.map_info[2]*widthfactor_2)/widthfactor +"vw",maxWidth: max_widthfactor * room.map_info[2], background: 'rgba(0, 0, 0, 0.35)'}}>
                </Card>
            </Col>
            )
        }
        let color = '#fffff';
        if(room.locked) color = '#DBDBDB'
        if(room.participants.filter(p => p._id === user._id).length > 0){
            color = '#e6f7ff';
        }

        let height=160
        //if(room.room_type === "ca") height = 100
        return (
            <Col>
                <Card className="room-card" title={room.title} bordered={true} extra={
                        !room.locked &&
                            <Button type="link" icon={<RightOutlined/>} onClick={this.showRoomDrawer}/>
                    }
                style={{height: height, minWidth: min_widthfactor * room.map_info[2], width: (room.map_info[2]*widthfactor_2)/widthfactor +"vw",maxWidth: max_widthfactor * room.map_info[2], background: color}}>
                    <div style={{overflowX: 'auto', margin: -16}}>
                        <Row style={{maxHeight: height -75}}>
                            {this.renderParticipants()}
                        </Row>
                    </div>
                </Card>
            </Col>
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    return {
        user,
        loggedIn
    };
}

const connectedRoomMapCard = connect(mapStateToProps)(RoomMapCard);
export { connectedRoomMapCard as RoomMapCard }; 
