import React from 'react';
import { connect } from 'react-redux';

import { roomActions } from '../../store/actions/room.actions';

import { VCHeader } from '../../components/VCHeader';
import { VCSider } from '../../components/VCSider';
import { RoomCard } from '../../components/RoomCard';

import qs from 'qs';

import { Layout, Row, PageHeader} from 'antd';
import 'antd/dist/antd.css';

import './HomePage.scss';
import { RoomPopup } from '../../components/RoomPopup';
import { ProfilePopup } from '../../components/ProfilePopup';

const {Content} = Layout;

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rooms: []
        };
    }

    componentDidMount() {
        this.requestRooms();
        // make sure everytime the query parameter of the url changes,
        // we update the state and load new rooms from the api
        const unregisterListener = this.props.history.listen((location) => {
            const query = qs.parse(location.search, { ignoreQueryPrefix: true });
            this.setState({
                rooms: query.results
            }, () => {
                this.requestRooms();
            })
        });

        this.setState({unregisterListener});
    }

    componentWillUnmount() {
        if (this.state.unregisterListener) {
            this.state.unregisterListener();
        }
    }

    requestRooms() {
        this.props.dispatch(roomActions.getAll());
    }

    hasRooms() {
        const { rooms } = this.props;
        return rooms && rooms.rooms && rooms.rooms.length >0;
    }

    shouldHideView() {
        const { rooms }  = this.props;
        return !rooms.initialLoadHappened;
    }

    renderRooms() {
        const { rooms, filter } = this.props;
        
        if (!rooms) {
            return '';
        }
        if (!rooms.rooms) {
            return '';
        }
        if (!rooms.rooms.length > 0) {
            return '';
        }
        if(filter === 'all'){
            return rooms.rooms
            .filter(r => !r.hidden)
            .filter(r => !(r.room_type === 'empty'))
            .filter(r => !(r.room_type === 'hallway'))
            .map(r => <RoomCard room={r} key={r._id} />)
        }
        else if (filter === 'active'){
            return rooms.rooms
            .filter(r => !r.hidden)
            .filter(r => r.participants.length > 0)
            .map(r => <RoomCard room={r} key={r._id} />)
        }
        else{
            return rooms.rooms
            .filter(r => !r.hidden)
            .filter(r => r.room_type === filter)
            .map(r => <RoomCard room={r} key={r._id} />)
        }
    }

    render() {
        const { rooms } = this.props;

        return (
            <Layout>
                <VCHeader/>
                <Layout style={{marginTop: 64}}>
                    <VCSider/>
                    <Content style={{ padding: '0', margin: '0 16px', overflow: 'hidden', minHeight: 'calc(100vh - 64px)'}}>
                    <PageHeader
                        className="site-page-header"
                        title="Virtual Center"
                        subTitle="See whats going on in the center and join in by clicking on a room"
                    />
                        <div style={{ paddingLeft: 24, paddingRight: 24, minHeight: 380 }}>
                            {rooms && rooms.rooms &&
                                <div>
                                    <Row>
                                        {this.renderRooms()}
                                    </Row>
                                </div>
                            }
                        </div>
                    </Content>
                </Layout>
                <RoomPopup/>
                <ProfilePopup/>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { login, rooms } = state;
    const {filter} = state.rooms;
    return {
        user: login.user,
        rooms: rooms,
        filter: filter
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
