import React from 'react';
import { connect } from 'react-redux';

import { authActions } from '../../store/actions';

import { Form, Input, Button, Alert, Col } from 'antd';

const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 8,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 4,
      span: 16,
    },
  };

class UpdatePasswordView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            confirmPassword: '',
            submitted: false
        };
    }

    onSubmit = values => {
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { password, newPassword } = values;
        dispatch(authActions.updatePassword(password, newPassword));
      };


    showFieldIndepenentErrorMessage() {
        const { errors } = this.props;
        // only show the field-independet error message, if the are none related to a specific field
        return errors && errors.message && (!errors.data || !errors.data.length);
    }

    render() {
        const {message, errors } = this.props;

        return (
            <div className="view-update-password">
                <Col style={{padding: 5, width: '100vh', minWidth: 200}}>
                    <h3>Change your password:</h3>
                </Col>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    hideRequiredMark={true}
                    onFinish={this.onSubmit}
                    /* onFinishFailed={onFinishFailed} */
                    >
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your current password!',
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please input new password with at least 6 characters!',
                            min: 6
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                
                              return Promise.reject('The two passwords that you entered do not match!');
                            },
                          }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                        Update password
                        </Button>
                    </Form.Item>
                    </Form>

                <div className="error-container">
                    {this.showFieldIndepenentErrorMessage() && 
                        <Alert message={errors.message} type="error" {...tailLayout}/>
                        
                    }
                </div>
                {message &&
                    <div className="info-container">
                        {message}
                    </div>
                }

                
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { updating, errors, message} = state.updatePassword;
    const { user } = state.login;
    return {
        updating,
        errors,
        message,
        user
    };
}

const connectedUpdatePasswordView = connect(mapStateToProps)(UpdatePasswordView);
export { connectedUpdatePasswordView as UpdatePasswordView };
