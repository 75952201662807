import React from 'react';
import { connect } from 'react-redux';

import { UpdatePasswordView } from './UpdatePasswordView';
import { UpdateUserView } from './UpdateUserView';
import { UpdatePictureView } from './UpdatePictureView';

import { VCHeader } from '../../components/VCHeader';
import { VCSider } from '../../components/VCSider';

import { Form, Input, Layout, PageHeader} from 'antd';

import './ProfilePage.scss';


const { Content } = Layout;

const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 8,
    },
  };

class ProfilePage extends React.Component {


    render() {
        const { user } = this.props;

        return (


            <Layout>
                <VCHeader/>
                <Layout style={{marginTop: 64}}>
                    <VCSider/>
                    <Content style={{ padding: '0', margin: '0 16px', overflow: 'auto', height: '100vh', }}>
                    <PageHeader
                        className="site-page-header"
                        title="Profile Settings"
                        subTitle=""
                    />
                        <div style={{ paddingLeft: 24, paddingRight: 24, minHeight: 380 }}>
                        {user && 
                    <div className="site-container">

                        <h3>{`Hello, ${user.firstName} ${user.lastName}!`}</h3>
                        
                        <UpdatePictureView />

                        <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        >
                            <Form.Item
                                label="E-Mail"
                                name="email"
                                initialValue={user.email}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Username"
                                name="username"
                                initialValue={user.username}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Form>
                        <UpdateUserView />
                        <UpdatePasswordView />
                    </div>
                }
                        </div>
                    </Content>
                </Layout>

            </Layout>


            
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.login;
    return {
        user
    };
}

const connectedProfilePage = connect(mapStateToProps)(ProfilePage);
export { connectedProfilePage as ProfilePage };
