import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { authActions } from '../../store/actions';

import { VCHeader } from '../../components/VCHeader';

import { Layout, Form, Input, Button, PageHeader, Alert, Divider, Typography, Space, Row, Col } from 'antd';
import 'antd/dist/antd.css';

import qs from 'qs';

import './LoginPage.scss';
const { Content} = Layout;
const { Text } = Typography;

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

        this.state = {
            redirect: query.redirect || '/map',
            email: query.email || '',
            password: '',
            submitted: false
        };

    }

    onSubmit = values => {
        this.setState({ submitted: true });
        const { dispatch } = this.props;
        const { email, password, redirect } = values;
        dispatch(authActions.login(email, password, redirect));
      };


    showFieldIndepenentErrorMessage() {
        const { errors } = this.props;
        // only show the field-independet error message, if the are none related to a specific field
        return errors && errors.message && (!errors.data || !errors.data.length);
    }
    

    render() {
        const { errors } = this.props;
        const { email } = this.state;
        return (
            <Layout>
                <VCHeader/>
                <Layout style={{marginTop: 64}}>
                <Content className="site-layout" style={{ padding: '0 3em', marginTop: 30, minHeight: 'calc(100vh - 64px)'}}>
                <PageHeader style={{paddingLeft: 0, paddingBottom: 0}}
                        className="site-page-header"
                        title="Welcome to the Virtual Center"
                        subTitle="Please login or create a new account in order to get acess to the Virtual Center Rooms"
                    />
                <div style={{backgroundColor: 'white', marginTop: 24, paddingBottom: 24}}>
                <Row>
                <Col span={12} style={{textAlign: 'center'}}>
                    <Divider orientation="center" style={{ padding: 24, paddingBottom: 0}}>Register</Divider>
                    <Space direction="vertical" style={{paddingLeft: "15%", paddingRight: "15%", textAlign: 'center'}}>
                    <Text type="secondary" style={{fontSize: 14}}>Please note that the Virtual Center is not connected to the CDTM Database yet. So please quickly sign up if you havent been here yet!</Text>
                    <br/>
                    <Button type="primary">
                        <Link to="/register">Click here to sign up</Link>
                    </Button>
                    </Space>
                </Col>
                <Col span={12} style={{textAlign: 'center'}}>
                    <Divider orientation="center" style={{ paddingLeft: 24, paddingTop: 24, paddingBottom: 12}}>Login</Divider>
                        <Form   style={{paddingLeft: "15%", paddingRight: "15%"}}
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.onSubmit}
                                hideRequiredMark={true}
                                >
                                    <Form.Item
                                        label="E-MAIL"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input a valid email!',
                                                type: 'email',
                                            },
                                            ]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="PASSWORD"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            ]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                        Login
                                        </Button>
                                    </Form.Item>
                                </Form>
                        <div className="error-container">
                            {this.showFieldIndepenentErrorMessage() && 
                                <Alert message={errors.message} type="error"/>
                            }
                        </div>
                    </Col>
                </Row>
                <Divider orientation="center" style={{ padding: 24, paddingBottom: 12}}>Other</Divider>
                <Space direction="vertical" style={{textAlign: 'center', width: '100%'}}>
                <Text type="secondary" style={{fontSize: '14px'}}>
                        {email &&
                            <Link to={`/forgot-password?email=${email}`}>Forgot your password?</Link>
                        }
                        {!email &&
                            <Link to={`/forgot-password`}>Forgot your password?</Link>
                        }
                    </Text>
                    <Text type="secondary" style={{fontSize: '14px'}}>
                        {email &&
                            <Link to={`/verify?email=${email}`}>Need to confirm your account?</Link>
                        }
                        {!email &&
                            <Link to={`/verify`}>Need to confirm your account?</Link>
                        }
                    </Text>
                </Space>
                </div>
                </Content>
                </Layout>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const { loading, errors } = state.login;
    return {
        loading,
        errors
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 