import { resourceConstants } from '../constants/resource.constants';

const initialState = { initialLoadHappened: false, loading: false, errors: {}, addResourceClicked: false, updateResourceClicked: false, resource_edit: null, resources_edited: [], resources_deleted: [], resource_temp: {}, resources: []};

export function resource(state = initialState, action) {
  switch (action.type) {
    case resourceConstants.ADD_RESOURCE_BUTTON_CLICKED:
      return {...state, ...{
        addResourceClicked: true,
        resource_temp: action.resource_temp
      }};
    case resourceConstants.ADD_RESOURCE_REQUEST_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case resourceConstants.ADD_RESOURCE_REQUEST_SUCCEEDED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: {},
        addResourceClicked: false,
        resource_temp: {},
        resources: [...state.resources, action.resourceData]
      }};
    case resourceConstants.ADD_RESOURCE_REQUEST_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};

    case resourceConstants.DELETE_RESOURCE_REQUEST_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case resourceConstants.DELETE_RESOURCE_REQUEST_SUCCEEDED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: {},
        resources_deleted: [...state.resources_deleted, action.resourceData]
      }};
    case resourceConstants.DELETE_RESOURCE_REQUEST_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    
    case resourceConstants.UPDATE_RESOURCE_BUTTON_CLICKED:
      return {...state, ...{
        updateResourceClicked: true,
        resource_edit: action.resource_temp
      }};
    case resourceConstants.UPDATE_RESOURCE_REQUEST_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case resourceConstants.UPDATE_RESOURCE_REQUEST_SUCCEEDED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: {},
        updateResourceClicked: false,
        resource_edit: null,
        resources_edited: [...state.resources_edited, action.resource]
      }};
    case resourceConstants.UPDATE_RESOURCE_REQUEST_FAILED:
        return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    case resourceConstants.LAST_PARTICIPANT_LEAVING_ROOM_INITIATED:
      return {...state, ...{
        loading: true
      }};
    case resourceConstants.LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED:
      return {...state, ...{
        selectedRoom: action.roomData,
        addResourceClicked: false,
        resource_temp: {},
        resources: []
      }};
    case resourceConstants.LAST_PARTICIPANT_LEAVING_ROOM_FAILED:
      return {...state, ...{
        initialLoadHappened: true, 
        loading: false,
        errors: action.error
      }};
    default:
      return state
  }
}
