import React from 'react';
import { connect } from 'react-redux';
import { roomActions } from '../store/actions';
import { Drawer, Divider, Avatar, Row, Col } from 'antd';
import { Typography } from 'antd';

const { Text } = Typography;

class ProfilePopup extends React.Component {

    constructor(props) {
        super(props);

        this.hideProfileDrawer = this.hideProfileDrawer.bind(this);
    }


    hideProfileDrawer() {
        this.props.dispatch(roomActions.hideProfileDrawer(this.props.profile))
    }

    render () {
        
        const { selectedProfile } = this.props
        if(selectedProfile) {
            return (
                <Drawer
                width={320}
                visible={this.props.showProfileDrawer}
                onClose={this.hideProfileDrawer}
                title={selectedProfile.fullName}
                >
                    <Row gutter={1}>
                        <Col span={8}><Avatar style={{height: 75, width: 75}} src={selectedProfile.profilePicture} /></Col>
                        <Col span={16}>
                            <Text strong>Current Status:</Text>
                            <br />
                            <Text>{selectedProfile.currentStatus}</Text>
                        </Col>
                    </Row>
                    
                    <Divider />

                    <Text strong>Email:</Text>
                    <br />
                    <Text>{selectedProfile.email}</Text>
                    <br />
                    <br />
                    <Text strong>Class:</Text>
                    <br />
                    <Text>{selectedProfile.classOf}</Text>
                    <br />
                    <br />
                    <Text strong>Linked-In:</Text>
                    <br />
                    <Text>{selectedProfile.website}</Text>
                    <br />
                    <br />
                    <Text strong>Phone number:</Text>
                    <br />
                    <Text>{selectedProfile.phoneNumber}</Text>
                </Drawer>
            )    
        }
        else{ return null }
        
    }
    
}


function mapStateToProps(state) {
    const { showProfileDrawer, selectedProfile } = state.rooms;
    return {
        showProfileDrawer,
        selectedProfile
    };
}

const connectedProfilePopup = connect(mapStateToProps)(ProfilePopup );
export { connectedProfilePopup as ProfilePopup  }; 