export const roomConstants = {
    GET_ROOMS_REQUEST_INITIATED: 'GET_ROOMS_REQUEST_INITIATED',
    GET_ROOMS_REQUEST_SUCCEEDED: 'GET_ROOMS_REQUEST_SUCCEEDED',
    GET_ROOMS_REQUEST_FAILED: 'GET_ROOMS_REQUEST_FAILED',

    GET_ROOM_REQUEST_INITIATED: 'GET_ROOM_REQUEST_INITIATED',
    GET_ROOM_REQUEST_SUCCEEDED: 'GET_ROOM_REQUEST_SUCCEEDED',
    GET_ROOM_REQUEST_FAILED: 'GET_ROOM_REQUEST_FAILED',

    HIDE_ROOM_DRAWER_REQUESTED: 'HIDE_ROOM_DRAWER_REQUESTED',
    SHOW_ROOM_DRAWER_REQUESTED: 'SHOW_ROOM_DRAWER_REQUESTED',
    
    HIDE_PROFILE_DRAWER_REQUESTED: 'HIDE_PROFILE_DRAWER_REQUESTED',
    SHOW_PROFILE_DRAWER_REQUESTED: 'SHOW_PROFILE_DRAWER_REQUESTED',

    CHANGE_ROOM_FILTER_REQUESTED: 'CHANGE_ROOM_FILTER_REQUESTED',

    ADD_PARTICIPANT_TO_ROOM_INITIATED: 'ADD_PARTICIPANT_TO_ROOM_INITIATED',
    ADD_PARTICIPANT_TO_ROOM_SUCCEEDED: 'ADD_PARTICIPANT_TO_ROOM_SUCCEEDED',
    ADD_PARTICIPANT_TO_ROOM_FAILED: 'ADD_PARTICIPANT_TO_ROOM_FAILED',

    KICK_PARTICIPANT_FROM_ROOM_INITIATED: 'KICK_PARTICIPANT_FROM_ROOM_INITIATED',
    KICK_PARTICIPANT_FROM_ROOM_SUCCEEDED: 'KICK_PARTICIPANT_FROM_ROOM_SUCCEEDED',
    KICK_PARTICIPANT_FROM_ROOM_FAILED: 'KICK_PARTICIPANT_FROM_ROOM_FAILED',

    CHANGE_CONVERSATION_URL_INITIATED: 'CHANGE_CONVERSATION_URL_INITIATED',
    CHANGE_CONVERSATION_URL_SUCCEEDED: 'CHANGE_CONVERSATION_URL_SUCCEEDED',
    CHANGE_CONVERSATION_URL_FAILED: 'CHANGE_CONVERSATION_URL_FAILED',

    CHANGE_SESSION_DESCRIPTION_INITIATED: 'CHANGE_SESSION_DESCRIPTION_INITIATED',
    CHANGE_SESSION_DESCRIPTION_SUCCEEDED: 'CHANGE_SESSION_DESCRIPTION_SUCCEEDED',
    CHANGE_SESSION_DESCRIPTION_FAILED: 'CHANGE_SESSION_DESCRIPTION_FAILED',

    LAST_PARTICIPANT_LEAVING_ROOM_INITIATED: 'LAST_PARTICIPANT_LEAVING_ROOM_INITIATED',
    LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED: 'LAST_PARTICIPANT_LEAVING_ROOM_SUCCEEDED',
    LAST_PARTICIPANT_LEAVING_ROOM_FAILED: 'LAST_PARTICIPANT_LEAVING_ROOM_FAILED'
}
