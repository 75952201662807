import React from 'react';
import { connect } from 'react-redux';

import { Col, Card, Space, Button, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { resourceActions } from '../store/actions/resource.actions';

const { Text} = Typography;

class Resource extends React.Component {
    
    render () {

        return (
            <Col style={{textAlign: 'center', padding: 5, width: '25vh', minWidth: 200}}>
                <Card title={this.props.resource.title} style={{height: '100%'}} extra={
                    <Space size={-5}>
                        {this.props.edit &&
                            <>
                            {this.props.user._id === this.props.resource.author &&
                                <Button type="link" icon={<DeleteOutlined/>} onClick={() => this.props.dispatch(resourceActions.clickDeleteResourceButton(this.props.resource))}/>
                            }
                            <Button type="link" icon={<EditOutlined/>} onClick={() => this.props.dispatch(resourceActions.clickUpdateResourceButton(this.props.resource))}/>
                            </>
                        }
                    </Space>
                }>

                    <Space direction="vertical" style={{width: '100%'}}>
                        <p style={{width: '100%', overflowWrap: 'break-word'}}>{this.props.resource.description}</p>
                        {this.props.resource.url &&
                        <Text>
                            <a href={this.props.resource.url} target="_blank" rel="noopener noreferrer" style={{width: '100%', overflowWrap: 'break-word'}}>
                                {this.props.resource.url}
                            </a>
                        </Text>
                        }
                    </Space>
                </Card>
            </Col>
        )
    }
}

//export { Resource }; 

function mapStateToProps(state) {
    const { loggedIn, user } = state.login;
    const { rooms, selectedRoom } = state.rooms;
    const { resources, addResourceClicked } = state.resource;

    return {
        user,
        loggedIn,
        rooms,
        selectedRoom,
        resources,
        addResourceClicked
    };
}

const connectedResource = connect(mapStateToProps)(Resource);
export { connectedResource as Resource };